import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Grid, Box, IconButton } from '@material-ui/core';
import { IconAlternate } from 'components/molecules';
import { CardReviewCharles, CardReviewAnnabelle, CardReviewJack } from 'components/organisms';
import { StaticImage } from 'gatsby-plugin-image';
import { Icon } from 'components/atoms';
import Swipe from 'swipejs';
import './review.css';

const useStyles = makeStyles(() => ({
}));

const Reviews = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  React.useEffect(() => {
    var element = document.getElementById('slider');
    window.slider = new Swipe(element, {
      startSlide: 0,
      auto: 1000000,
      draggable: false,
      autoRestart: false,
      continuous: true,
      disableScroll: true,
      stopPropagation: true,
      callback: function (index, element) { },
      transitionEnd: function (index, element) { }
    });
  });

  return (
    <>
      <div className={className} data-aos="fade-up" {...rest} style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <Box mr={4}>
          <IconButton
            aria-label="previous review"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => window.slider.prev()}>
            <Icon
              fontIconClass='fas fa-chevron-left'
            />
          </IconButton>
        </Box>
        <div id="slider" class="swipe">
          <div class="swipe-wrap">
            <div>
              <CardReviewCharles
                style={{
                  margin: 'auto'
                }}
                key={'charleslouis'}
                className={'swiper-slide'}
                noBorder
                noShadow
                text='Qeerio is incredibly easy to get started with. We use it on all our events for many well-known brands and completely trust it!'
                icon={
                  <IconAlternate
                    color={colors.indigo}
                    fontIconClass="fas fa-quote-right"
                  />
                }
                authorName='Charles-Louis de Lovinfosse'
                authorTitle='Guns for Hire, Artistic Director & Event Director'
                authorPhoto={null}
              /></div>
            <div ><CardReviewAnnabelle
              style={{
                margin: 'auto'
              }}
              key={'annabelle'}
              className={'swiper-slide'}
              noBorder
              noShadow
              text='The time saved before and after the event for the check-in management is insane. The time saved, the affordable prices and guest satisfaction makes it a no brainer.'
              icon={
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="fas fa-quote-right"
                />
              }
              authorName='Annabelle Mekari'
              authorTitle='Profirst, Project Coordinator'
              authorPhoto={null}
            /></div>
            <div><CardReviewJack
              style={{
                margin: 'auto'
              }}
              key={'jack'}
              className={'swiper-slide'}
              noBorder
              noShadow
              text='Customizing the application theme for each one of our clients is a game changer! We always get great feedback from our clients and hostesses.'
              icon={
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="fas fa-quote-right"
                />
              }
              authorName='Jack Smith'
              authorTitle='Head of Operations, Parkfield Commerce'
              authorPhoto={null}
            /></div>
          </div>
        </div>
        <Box ml={4}>
          <IconButton
            aria-label="next review"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => window.slider.next()}>
            <Icon
              fontIconClass='fas fa-chevron-right'
            />
          </IconButton>
        </Box>
      </div>
      <Box mt={9}>
        <Grid container spacing={10} alignItems='center'>
          <Grid
            item
            xs={4}
            md={2}
          >
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '17px'
              }}
              src='../../../../assets/images/qeerio/client-logo/bnp_paribas.png'
              alt="Bnp logo"
              placeholder="blurred"
              lazy
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '8px'
              }}
              src='../../../../assets/images/qeerio/client-logo/deutsche_bank.png'
              alt="Deutschebank logo"
              placeholder="blurred"
              lazy
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '17px'
              }}
              src='../../../../assets/images/qeerio/client-logo/profirst.png'
              alt="Profirst logo"
              placeholder="blurred"
              lazy
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '14px'
              }}
              src='../../../../assets/images/qeerio/client-logo/redken.png'
              alt="Redken logo"
              placeholder="blurred"
              lazy
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '10px'
              }}
              src='../../../../assets/images/qeerio/client-logo/dior.png'
              alt="Dior logo"
              placeholder="blurred"
              lazy
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '10px'
              }}
              src='../../../../assets/images/qeerio/client-logo/porsche.png'
              alt="Porsche logo"
              placeholder="blurred"
              lazy
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Reviews.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Reviews;
