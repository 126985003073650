import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  }
}));

const Professional = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={<div className={classes.textWhite}><span className={classes.textOrange}>Designed</span> for an{' '}<span className={classes.textOrange}>effortless</span>{' '}check-in</div>}
        titleVariant="h3"
        ctaGroup={[
          <Box mt={8}>
            <Button variant="outlined" color="secondary" size="large" href="/features" aria-label="Discover our features">
              Discover our features
            </Button>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

Professional.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default Professional;
